import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
// Images
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeRacks = () => {
  return (
    <Layout>
      <Seo
        title="Fahrradanlehnbügel - BIKESAFE"
        description="Unsere Fahrradständer bieten Platz für 1 bis 24 Fahrräder in nur einem Ständer an! Jedes unserer Produkte ist rostbeständig, und trotzt nicht nur dem Wetter, sondern auch Diebstahlversuchen."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center uppercase">
            Fahrradanlehnbügel
            </h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <p className="flow-text">
            Unsere Fahrradständer bieten Platz für 1 bis 24 Fahrräder in nur einem Ständer an! Jedes unserer Produkte ist rostbeständig, und trotzt nicht nur dem Wetter, sondern auch Diebstahlversuchen.
            </p>
            <p className="flow-text">Entdecken Sie hier unsere nachstehende Auswahl :</p>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <hr />
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/61">
              <StaticImage placeholder="blurred" className="fluid" src="../product/61/homepage-61.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Doppelstock-Fahrradparker</h3>
              <p>Platzsparend, großartige Benutzerfreundlichkeit und ausgezeichnetes Preis-Leistungs-Verhältnis! Der Doppelstock-Fahrradparker ist ein sehr innovativer und benutzerfreundlicher Fahrradständer...</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/230">
              <StaticImage placeholder="blurred" className="fluid" src="../product/230/homepage-230.jpg"></StaticImage>
              <h3 className="uppercase mt-3">BikeSafe Doppelstock-Fahrradparker</h3>
              <p>Der BikeSafe Two Tier Bike Rack ist ein wegweisendes und benutzerfreundliches Fahrradparksystem mit ausgezeichneter platzsparender Fahrradaufbewahrung, hervorragender Benutzerfreundlichkeit und...</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/80">
              <StaticImage placeholder="blurred" className="fluid" src="../product/80/homepage-80.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Zweiseitiger vertikaler Fahrradparker</h3>
              <p>Mit diesem Produkt können die Nutzer ihre verfügbare Bodenfläche maximal nutzen. Der Rahmen kann überall im Raum sicher am Boden verschraubt werden. Ideal für Büros, Wohnobjekte oder Schulen...</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
          <Col sm="12" lg="6" className="p-2">
            <Link to="/product/88">
              <StaticImage placeholder="blurred" className="fluid" src="../product/88/homepage-88.jpg"></StaticImage>
              <h3 className="uppercase mt-3">Semi-vertikaler Fahrradparker</h3>
              <p>Optimiert die Nutzung des vorhandenen Platzes, wo dieser knapp bemessen ist. Der semi-vertikale Parker kann sowohl im Innen- als auch im Außenbereich genutzt werden. Das Hinterrad kann mithilfe...</p>
              <p className="shop-now">Ein Angebot bekommen</p>
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BikeRacks;